.three-dots-container {
    display: flex;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000;
    margin: 0 5px;
    opacity: 0;
    animation: dotAnimation 1.5s infinite;
  }
  
  @keyframes dotAnimation {
    0% {
      opacity: 0;
    }
    33% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  
  